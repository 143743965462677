@font-face {
    font-family: 'Pragmatica';
    src: url('./Pragmatica-Medium.woff') format('woff'), url('./Pragmatica-Medium.woff2') format('woff2'), url('./Pragmatica-Medium.ttf') format('ttf');
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: 'Pragmatica';
    src: url('./Pragmatica-Book.woff') format('woff'), url('./Pragmatica-Book.woff2') format('woff2'), url('./Pragmatica-Book.ttf') format('ttf');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('./BebasNeue-Regular.woff') format('woff'), url('./BebasNeue-Regular.woff2') format('woff2');
    font-style: normal;
    font-weight: 400;
}

